import { useEngagementsStore } from 'store/engagementsStore/engagementsStore';
import { useDesignStyleStore } from 'store/designStyleStore/designStyleStore';
import { localStoreDataPrev } from 'store/localStoreData/localStoreDataPrev';
import { useLocalStoreData } from 'store/localStoreData/localStoreData';
import { useTargetingStore } from 'store/targetingStore/targetingStore';
import { useChannelsStore } from 'store/channelsStore/channelsStore';
import { useGeneralStore } from 'store/generalStore/generalStore';
import { useRoutingStore } from 'store/routingStore/routingStore';
import { useBoundStore } from 'store/useBoundStore';

export const resetStore = () => {
  useBoundStore.setState({
    ...useGeneralStore(),
    ...useChannelsStore(),
    ...useDesignStyleStore(),
    ...useEngagementsStore(),
    ...useTargetingStore(),
    ...useRoutingStore(),
  });

  useLocalStoreData.setState({
    ...localStoreDataPrev(),
  });

  return true;
};

export const resetBoundStore = () => {
  useBoundStore.setState({
    ...useGeneralStore(),
    ...useChannelsStore(),
    ...useDesignStyleStore(),
    ...useEngagementsStore(),
    ...useTargetingStore(),
    ...useRoutingStore(),
  });

  return true;
};

export const resetLocalStore = () => {
  useLocalStoreData.setState({
    ...localStoreDataPrev(),
  });

  return true;
};
