import { create } from 'zustand';
import { produce } from 'immer';

import { localStoreDataPrev } from './localStoreDataPrev';

export const useLocalStoreData = create(() => ({
  ...localStoreDataPrev(),
}));

export const changeWidgetState = (value: string) => {
  useLocalStoreData.setState(
    produce((state) => {
      state.widgetPreview.state = value;
    }),
  );
};

export const changeIsMobile = (value: boolean) => {
  useLocalStoreData.setState(
    produce((state) => {
      state.widgetPreview.isMobile = value ? 'mobile' : 'desktop';
    }),
  );
};
