import { GeneralInterface } from './GeneralInterface';

export const useGeneralStore = (): GeneralInterface => ({
  general: {
    about: {
      active: true,
      lastEdit: '',
      created: '',
      priority: 'A',
    },
    name: {
      widgetName: '',
      oldWidgetName: '',
      widgetDescription: '',
    },
    language: {
      selectedLanguageValue: 'en',
    },
    additionalSettings: {
      singleChannelMode: false,
      chatTranscript: false,
    },
    aiHeadsUpTexts: false,
  },
});
