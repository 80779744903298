import { RoutingInterface } from '../../store/routingStore/RoutingInterface';
import { useBoundStore } from '../../store/useBoundStore';
import { produce } from 'immer';
import axios from 'axios';

const updateNumber = (value: string) => {
  useBoundStore.setState(
    produce((state: RoutingInterface) => {
      state.routing.callUs.phone = value ? value : '';
      state.routing.video.phone = value ? value : '';
    }),
  );
};

export const getUserNumber = async () => {
  try {
    const routingCallUsData = await axios.get(
      `${process.env.REACT_APP_API_URL}/v2/genesys_dids?filter%5Bwidget_channel_id%5D=null&page%5Bsize%5D=1`,
    );
    if (routingCallUsData) {
      updateNumber(routingCallUsData.data.data[0]?.attributes?.phone_number);
    }
  } catch (error) {
    console.error('Error fetching genesys number:', error);
  }
};
