import React, { createContext, ReactNode, useEffect, useState } from 'react';
import axios from 'axios';

import { AccountDataInterface, UserDataInterface } from './userDataInterface';
import { getLocalAuthToken } from 'utils/getLocalAuthToken';
import { useLocation, useSearchParams } from 'react-router-dom';

interface UserDataInf {
  loggedInUserData: UserDataInterface | null;
  companyAccountData: AccountDataInterface | null;
  refetchUserData: () => void;
  userLoading: boolean;
}

interface AuthContext {
  userData: UserDataInf | null;
}

const UserDataContext = createContext<AuthContext>({
  userData: null,
});

export const useUserData = () => {
  return React.useContext(UserDataContext);
};

interface UserDataProviderProps {
  children: ReactNode;
}

export const UserDataProvider = ({ children }: UserDataProviderProps) => {
  const [loggedInUserData, setLoggedInUserData] = useState<UserDataInterface | null>(null);
  const [companyAccountData, setCompanyAccountData] = useState<AccountDataInterface | null>(null);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleLogout = () => {
    localStorage.removeItem('ember_simple_auth-session');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('viewAsAccount');
    localStorage.removeItem('currentAgency');
    localStorage.removeItem('viewAsAccountName');
    localStorage.removeItem('currentUserId');
  };

  const apiUrl = `${process.env.REACT_APP_API_URL}/v2/users/me?include=account`;
  const viewAsAccount = localStorage.getItem('viewAsAccount');

  const getUserData = async () => {
    const token = getLocalAuthToken();
    if (!token) {
      setUserLoading(false);
      return;
    }
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: `Barer ${token}`,
        },
      });
      if (response) {
        axios.defaults.headers.common['Authorization'] = token;
        setLoggedInUserData(response.data.data);

        localStorage.setItem(
          'currentUser',
          response.data.data.relationships.account.data?.id ? response.data.data.relationships.account.data.id : null,
        );
        localStorage.setItem(
          'currentAgency',
          response.data.data.relationships.agency.data?.id ? response.data.data.relationships.agency.data.id : null,
        );
        localStorage.setItem('currentUserId', response.data.data.id ? response.data.data.id : null);

        if (response.data.included && response.data.included.length > 0) {
          setCompanyAccountData(response.data.included[0]);
        } else {
          setCompanyAccountData(null);
        }
        setUserLoading(false);
      }
    } catch (error) {
      handleLogout();
      setUserLoading(false);
    }
  };

  const getAccountData = async () => {
    if (viewAsAccount) {
      setUserLoading(true);
      const accountApiUrl = `${process.env.REACT_APP_API_URL}/v2/accounts/${viewAsAccount}`;
      const response = await axios.get(accountApiUrl);
      setCompanyAccountData(response.data.data);
      setUserLoading(false);
    }
  };

  useEffect(() => {
    getUserData().then(() => getAccountData());
  }, []);

  const refetchUserData = () => {
    getUserData().then(() => getAccountData());
  };

  useEffect(() => {
    const refetch = searchParams.get('refetch');
    const currentPath = location.pathname.split('/')[1];

    if(currentPath==='widget'){
      refetchUserData();
    }
    if (refetch) {
      setSearchParams('');
      refetchUserData();
    }
  }, [location.pathname]);

  const userData = { loggedInUserData, companyAccountData, userLoading, refetchUserData };

  return <UserDataContext.Provider value={{ userData }}>{children}</UserDataContext.Provider>;
};
