import { LoadingRing, LoadingWrapper } from './LoadingStyle';

interface LoadingProps{
    small?:boolean
    takeFullSpace?:boolean
}
const Loading = ({small,takeFullSpace=true}:LoadingProps) => {
  return (
    <LoadingWrapper $small={small} $takeFullSpace={takeFullSpace}>
      <LoadingRing $small={small}>
        <div />
        <div />
        <div />
        <div />
      </LoadingRing>
    </LoadingWrapper>
  );
};

export default Loading;
