import { LocalStoreDataInterface } from './localStoreDataInterface';

export const localStoreDataPrev = (): LocalStoreDataInterface => ({
  widgetPreview: {
    isMobile: 'desktop',
    state: 'mainView',
    oneChannelOnly: false,
  },
  widgetData: {
    uploadedFiles: [],
    sessionUploadedFiles: [],
  },
  widgetList:{
    refreshWidgets:true,
    widgetId:'',
  }
});
