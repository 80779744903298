import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import React from 'react';

import { UserDataProvider } from 'hooks/useUserData/useUserData';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <UserDataProvider>
      <App />
    </UserDataProvider>
  </Router>,
);
