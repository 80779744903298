import { TargetingInterface } from './targetingInterface';

export const useTargetingStore = (): TargetingInterface => ({
  targeting: {
    priority: 'A',
    oldPriority: 'A',
    backendId: '',
    specifiedPageAddress: {
      active: false,
      data: [],
    },
    utm: {
      active: false,
      data: [],
    },
    specifiedDevice: {
      desktopActive: false,
      oldDesktopActive: false,
      mobileActive: false,
      oldMobileActive: false,
    },
    specifiedSource: {
      active: false,
      source: 'All sources',
    },
    specifiedCountries: {
      active: false,
      oldActive: false,
      countryValue: [],
      oldCountryValue: [],
      countriesMatch: true,
      oldCountriesMatch: true,
    },
  },
});
