import routesConfig, { signOnManagementRoutes } from '../routes/routesConfig';
import { useNavigate } from 'react-router-dom';

export const handleRedirects = () => {
  const navigate = useNavigate();
  const viewAsAccount = localStorage.getItem('viewAsAccount');
  const routeConfig = routesConfig.find((route) => route.path === location.pathname);

  const handleRedirect = (role: string | null, currentPath: string) => {
    if (routeConfig?.requiresAuth && !role) {
      navigate('/login');
    }

    if (!signOnManagementRoutes.includes(currentPath) && !role) {
      navigate('/login');
    }

    if (role && signOnManagementRoutes.includes(currentPath)) {
      if (role === 'agency_admin' || role === 'superadmin') {
        navigate('/resell/management?tab=customers');
      }
      if (role !== 'agency_admin' && role !== 'superadmin') {
        navigate('/widgets');
      }
    }

    if (currentPath === '' && (role === 'agency_admin' || role === 'superadmin')) {
      navigate('/resell/management?tab=customers');
    }
    if (currentPath === '' && role !== 'agency_admin' && role !== 'superadmin') {
      navigate('/widgets');
    }

    if (!viewAsAccount) {
      if ((role === 'agency_admin' || role === 'superadmin') && currentPath !== 'resell') {
        navigate('/resell/management?tab=customers');
      }

      if (role !== 'superadmin' && role !== 'agency_admin' && currentPath === 'resell') {
        navigate('/widgets');
      }
    }
    if (viewAsAccount) {
      if (location.pathname === '/' || currentPath === 'resell') navigate('/widgets');
    }
  };

  return { handleRedirect };
};
