const colors = {
  primary: 'rgb(0, 184, 170)',
  primaryHover: 'rgba(0, 184, 170, 0.20)',
  primaryActive: 'rgba(0, 184, 170, 0.20)',
  secondary: '#D9D9D9',
  secondaryHover: 'rgba(255, 100, 40, 0.20)',
  secondaryActive: 'rgba(255, 100, 40, 0.20)',
  textPrimary: '#171754;',
  textSecondary: '#515578',

  advancedPalette: {
    gray: '#8e8e8e',
    darkGray: '#b9b9b9',
    borderDark: '#C3C3C3',
    borderLight: '#0000001f',
    backgroundDark: 'rgba(84, 86, 90, 0.12)',
    backgroundLight: 'rgba(158, 159, 162, 0.64)',
  },
  blue: {
    '50': '#EFF2FF',
    '100': '#DBE2FE',
    '200': '#BFCDFE',
    '300': '#94AEFC',
    '400': '#6183F9',
    '500': '#3D5AF5',
    '600': '#2637EA',
    '700': '#1E25D7',
    '800': '#1F20AE',
    '900': '#1F2289',
    '950': '#171754',
  },
  lightBlue: {
    '50': '#F0F8FF',
    '100': '#E0F1FE',
    '200': '#BBE3FC',
    '300': '#7FCDFA',
    '400': '#3CB5F5',
    '500': '#119CE6',
    '600': '#057CC4',
    '700': '#06629E',
    '800': '#095383',
    '900': '#0E466C',
    '950': '#092D48',
  },
  green: {
    '50': '#EEFBF3',
    '100': '#D6F5E1',
    '200': '#B1E9C7',
    '300': '#7DD8A8',
    '400': '#62C895',
    '500': '#25A469',
    '600': '#178453',
    '700': '#136945',
    '800': '#115438',
    '900': '#0F4530',
    '950': '#07271B',
  },
  red: {
    '50': '#FEF2F3',
    '100': '#FEF2F3',
    '200': '#FBD0D4',
    '300': '#F7AAB2',
    '400': '#F27A8A',
    '500': '#EB6177',
    '600': '#D42A4D',
    '700': '#B31D40',
    '800': '#961B3C',
    '900': '#801B39',
    '950': '#470A1A',
  },
  yellow: {
    '50': '#FFFCEB',
    '100': '#FDF3C8',
    '200': '#FBE88C',
    '300': '#F9D85B',
    '400': '#F7C228',
    '500': '#F0A310',
    '600': '#D57C0A',
    '700': '#B1570C',
    '800': '#8F4411',
    '900': '#763911',
    '950': '#441C04',
  },
  grey: {
    '50': '#F6F6F9',
    '100': '#ECEDF2',
    '200': '#D5D7E2',
    '300': '#B0B3C9',
    '400': '#858AAB',
    '500': '#606588',
    '600': '#515578',
    '700': '#434561',
  },
  backgroundColor: '#F6F6F9',
  white:'#FFFFFF'
};

export default colors;
