import { create } from 'zustand';

import { useEngagementsStore } from './engagementsStore/engagementsStore';
import { useDesignStyleStore } from './designStyleStore/designStyleStore';
import { useTargetingStore } from './targetingStore/targetingStore';
import { useChannelsStore } from './channelsStore/channelsStore';
import { useGeneralStore } from './generalStore/generalStore';
import { useRoutingStore } from './routingStore/routingStore';

export const useBoundStore = create(() => ({
  ...useGeneralStore(),
  ...useChannelsStore(),
  ...useDesignStyleStore(),
  ...useEngagementsStore(),
  ...useTargetingStore(),
  ...useRoutingStore(),
}));