import styled from 'styled-components';
import colors from './variables/colors';

export const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ComponentWithSidebar = styled.div`
  display: flex;
  flex: 1;
  background: ${colors.grey['50']};
`;

export const ViewAsComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  max-width: 300px;
  min-width: 200px;
  height: 40px;
  background: white;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  z-index: 9999;
  border: 1px solid lightgray;
  flex-shrink: 0;

  div {
    display: flex;
    flex-direction: column;

    span:first-child {
      font-weight: 400;
      white-space: nowrap;
      font-size: 14px;
    }

    span:last-child {
      color: ${colors.grey['700']};
      font-weight: 500;
      font-size: 15px;
      white-space: nowrap;
    }
  }

  button {
    padding: 4px 16px;
    border-radius: 8px;
    border: 1px solid ${colors.grey['400']};
    background: none;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    margin-left: auto;

    &:hover {
      background: ${colors.grey['50']};
    }
  }
`;
