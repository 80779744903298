export const useEngagementsStore = () => ({
  engagement: {
    bubble: {
      playSound: false,
      oldPlaySound: false,
      onLoad: {
        active: true,
        value: 20,
        oldValue: 20,
        id: '',
      },
      timeSpent: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      scrollPercentage: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      exitIntent: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      segment: {
        active: false,
        segmentId: '',
        previousSegmentId: '',
        actionMapExist: false,
      },
    },
    bubbleAnimation: {
      playSound: false,
      oldPlaySound: false,
      onLoad: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      timeSpent: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      scrollPercentage: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      exitIntent: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      segment: {
        active: false,
        segmentId: '',
        previousSegmentId: '',
        actionMapExist: false,
      },
    },
    popover: {
      playSound: false,
      oldPlaySound: false,
      segmentId: '',
      previousSegmentId: '',
      onLoad: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      timeSpent: {
        active: true,
        value: 10,
        oldValue: 10,
        id: '',
      },
      scrollPercentage: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      exitIntent: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      segment: {
        active: false,
        segmentId: '',
        previousSegmentId: '',
        actionMapExist: false,
      },
    },
    popup: {
      playSound: false,
      oldPlaySound: false,
      segmentId: '',
      previousSegmentId: '',
      onLoad: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      timeSpent: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      scrollPercentage: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      exitIntent: {
        active: false,
        value: 20,
        oldValue: 20,
        id: '',
      },
      segment: {
        active: false,
        segmentId: '',
        previousSegmentId: '',
        actionMapExist: false,
      },
    },
  },
});
