import axios from 'axios';

export const removeFile = async (fileKey: string) => {
  const url = `${process.env.REACT_APP_API_URL}/v2/files/${fileKey}`;
  let config = {};

  const viewAsAccount = localStorage.getItem('viewAsAccount');
  if (viewAsAccount) {
    const payload = {
      account_id: viewAsAccount,
    };
    config = {
      data: payload,
    };
  }

  try {
    await axios.delete(url, config);
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};
