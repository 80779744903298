import styled from 'styled-components';

interface LoadingRingProps {
  $small?: boolean;
  $takeFullSpace?: boolean;
}

export const LoadingRing = styled.div<LoadingRingProps>`
  align-self: center;
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  display: inline-block;
  position: relative;
  width: ${({ $small }) => ($small ? '50px' : '80px')};
  height: ${({ $small }) => ($small ? '50px' : '80px')};

  margin: 0 auto;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ $small }) => ($small ? '32px' : '64px')};
    height: ${({ $small }) => ($small ? '32px' : '64px')};
    margin: 8px;
    border-style: solid;
    border-width: ${({ $small }) => ($small ? '4px' : '8px')};
    border-color: lightgray transparent transparent transparent;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const LoadingWrapper = styled.div<LoadingRingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $takeFullSpace }) => ($takeFullSpace ? '100%' : 'unset')};
  height: ${({ $takeFullSpace }) => ($takeFullSpace ? '100%' : 'unset')};
`;
